
import { useEffect, useState } from 'react';
import QuoteComponent from '../../components/QuoteComponent/QuoteComponent';
import adminServices from '../../services/httpServices';
import { Spin } from 'antd';
import NotificationService from '../../NotificationService';
const WhoWeAre = () => {

  return (
    <div className=" w-full ">
      <div className="flex flex-col gap-10 ">
        <p className="flex justify-center lg:items-start items-center text-center lg:text-justify  flex-col   lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
          Welcome to Heritage Capital Markets Limited{" "}
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>

        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            At Heritage Capital Markets Limited, you can enjoy a personal
            relationship with our investment professionals. We see investing
            only from your perspective, and make recommendations based on
            actually listening to you and understanding your needs. We won't
            push unsuitable securities and investments to you because we realize
            that your goals and needs are completely unique.
          </p>

          <p>
            To all investors, we offer investment guidance and portfolio
            management designed to ensure a blend of inflation adjusted capital
            preservation and growth. If you are busy and you want us to manage
            your portfolio, we also offer investment management services. While
            we take the pressure of your hands, we keep you as involved as you
            want to be.
          </p>
          <div className="flex flex-col gap-10 ">
      <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
      Company Background
        <span className="border-[1px] border-[#b49132] w-[5%]"></span>
      </p>

      <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
       
        <p>
        Heritage Capital Markets Limited is a boutique Securities Dealing House, focused on creating value for our various stakeholders; we are built on good reputation , integrity and sound capital market competencies.
        </p>
<p>
To this end, we would like to introduce <span className='text-[#b49132]'>Heritage Capital Markets Limited
    </span>  to you and your reputable organisation for investment services.
</p>
      </div>


     <div className='flex flex-col gap-8  text-[18px] text-justify leading-loose text-[#424242]'>
     
      <p>Our clients cut across all sectors and they are major players in the public and private sectors. Our prompt and quality service coupled with our professionalism has earned us their loyalty which they can testify.</p>
     </div>
    </div>
          <p className="text-[#b49132] font-[500]">
          -Our goal is simple: to maximize your investments / to build the tomorrow you want. 
          </p>

         
        </div>

        <QuoteComponent  />

       
      </div>
      
    </div>
  );
};

export default WhoWeAre;
