import { useEffect, useState } from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import adminServices from "../../services/httpServices";
import { Spin } from "antd";
import NotificationService from "../../NotificationService";
const QuoteComponent = () => {
  const [isloading, setisloading] = useState(false);
  const [quoteData, setQuoteData] = useState([]);

  const fetch = async () => {
    setisloading(true);
    try {
      const res = await adminServices.MissionVision();
      setQuoteData(res?.data);

      setisloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setisloading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Spin
      spinning={isloading}
      delay={500}
      size="large"
      tip={"Fetching Mission&Vision Data...."}
      className=" text-[#9b7c27] font-bold"
    >
      <div className="flex flex-col gap-2 ">
        <div className=" flex flex-col justify-center items-center bg-[#001529] rounded-lg pb-2">
          <p className="text-[18px] text-[#9b7c27] py-2  font-bold ">
            Our Vision
          </p>
          <div className="flex justify-center items-center   text-[18px] font-[600] text-[#fcfcfc] text-start px-2  gap-5 leading-loose">
            <span className="text-[10px]">
              <FaQuoteLeft />
            </span>
            <p className="text-[14px]">{quoteData[0]?.vission}</p>

            <span className="text-[10px]">
              <FaQuoteRight />
            </span>
          </div>
        </div>

        <div className=" flex flex-col justify-center items-center bg-[#001529] rounded-lg pb-2">
          <p className="text-[18px] text-[#9b7c27] py-2  font-bold ">
            Our Mission
          </p>
          <div className="flex justify-center items-center   text-[18px] font-[600] text-[#fcfcfc] text-start px-2 gap-5 leading-loose">
            <span className="text-[10px]">
              <FaQuoteLeft />
            </span>
            <p className="text-[14px]">{quoteData[0]?.mission}</p>

            <span className="text-[10px]">
              <FaQuoteRight />
            </span>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default QuoteComponent;
