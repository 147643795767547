import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import getstarted from "../../Assets/get_started_side_2.jpg";
import { Table } from "antd";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";
import CurrencyVsPriceComponent from "../../components/CurrencyVsPrice/CurrencyVsPrice.Component";
import { TiChevronRightOutline } from "react-icons/ti";
const ResearchParentPage = () => {
  const location = useLocation();

  return (
    <div>
      <div
        className="bg-cover bg-[center] bg-no-repeat  flex justify-center flex-col items-center h-[45vh] gap-10"
        style={{
          backgroundImage: `url(${getstarted})`,
        }}
      >
        <p className="  text-[41px]  text-white font-[400] flex_center flex-col">
          {location.pathname === "/Research/"
            ? "Current Stock Prices"
            : location.pathname === "/Research/top_gainers"
            ? "Gainers for Equity"
            : location.pathname === "/Research/top_losers"
            ? "Losers for Equity"
            : location.pathname === "/Research/Company_Research"
            ? "Our Research"
            : null}
          <span className="border-[1px] w-[20%]"></span>
        </p>

        <ul className="flex_center text-white gap-3 font-[600] leading-loose">
          <li >
            <Link className="flex justify-center items-center gap-1" to="/">Home <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link className="flex justify-center items-center gap-1" to="/Research/">Stock Price <TiChevronRightOutline /></Link>
          </li>
          <li >
            <Link>
              {location.pathname === "/Research/"
                ? null
                : location.pathname === "/Research/top_gainers"
                ? "Top Gainers"
                : location.pathname === "/Research/top_losers"
                ? "Top losers"
                : location.pathname === "/Research/Company_Research"
                ? "Our Research"
                : null}
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex 2xl:w-[85%] lg:w-[90%] gap-8 gap-8 md:mb-4 mb-0 pt-8 mx-auto lg:flex-row flex-col">
        <div className="lg:min-w-[65%] lg:w-[65%] lg:max-w-[65%] ">
        <Outlet />
        </div>
        
        <div className="flex flex-col  lg:min-w-[35%] lg:max-w-[35%]    gap-5">
          <div className="">
            <p className="border flex flex-col  bg-[#f4f4f4] ">
              <NavLink
                to="/Research/"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Latest Stocks
              </NavLink>
              <NavLink
                to="/Research/top_gainers"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Gainers for equity
              </NavLink>
              <NavLink
                to="/Research/top_losers"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Top losers
              </NavLink>
              <NavLink
                to="/Research/Company_Research"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Research
              </NavLink>
              {/* <NavLink
                to="/Research/Boards"
                className={({ isActive }) =>
                  isActive
                    ? "bg-[#b49132] font-[600] border-b border-grey px-2  py-4 text-white"
                    : "border-b border-grey px-2  py-4 hover:text-[white] text-[16px] font-[400] text-[#373c5f] hover:bg-[#b49132]"
                }
              >
                Board of Directors
              </NavLink> */}
            </p>
          </div>
         

          <div
            className=" w-[full]  text-white  flex justify-center items-center my-8 bg-center bg-cover"
            style={{
              backgroundImage: `url(${getstarted})`,
            }}
          >
            <div className="flex justify-between items-center w-[85%] mx-auto py-12 flex-col gap-5 text-center ">
              <p className="flex flex-col text-[21px] leading-[1.8] capitalize">
                manage your Investment with more confidence.{" "}
                <span className="text-[18px]">
                  Open a trading Account today and kickstart your investments
                </span>
              </p>
              <button className="rounded px-8 bg-[#b49132]  py-2 text-[16px] uppercase leading-loose hover:bg-[#313140] hover:text-white ease-in-out duration-[0.5s]">
                Get Started
              </button>
            </div>
          </div>
          <QuoteComponent />
        </div>
      </div>
    </div>
  );
};

export default ResearchParentPage;
