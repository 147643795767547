import { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "./research.css";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import { formattedDate } from "../../helpers/helpers";

const CurrentPriceList = () => {
  const [searchParam, setSerachParams] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [allTableData, setallTableData] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const columns = [
    {
      title: "Company",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "PCLOSE",
      dataIndex: "pClose",
      key: "pClose",
    },
    {
      title: "OPEN",
      key: "openingPrice",
      dataIndex: "openingPrice",
    },
    {
      title: "HIGH",
      dataIndex: "high",
      key: "high",
    },
    {
      title: "LOW",
      dataIndex: "low",
      key: "low",
    },
    {
      title: "CLOSE",
      key: "close",
      dataIndex: "close",
    },
    {
      title: <p className=" text-center">CHG </p>,
      dataIndex: "percentageChange",
      key: "percentageChange",
      render: (text) => (
        <div className=" w-full flex justify-center items-center">
          <Tag color={`${text === 0 ? "blue" : text < 0 ? "red" : "green"}`}   className=" lg:min-w-[60%] lg:mx-auto text-center" >
            {text < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
            {text?.toLocaleString()}
          </Tag>
        </div>
      ),
    },

    {
      title: "TRD",
      dataIndex: "trades",
      key: "trades",
    },
    {
      title: "VOL",
      key: "volume",
      dataIndex: "volume",
      render: (text) => <p> {text.toLocaleString()}</p>,
    },
    {
      title: "VAL(₦)",
      dataIndex: "value",
      key: "value",
      render: (text) => <p> {text.toLocaleString()}</p>,
    },
    {
      title: "Trade Date",
      key: "tradeDate",
      dataIndex: "tradeDate",
      // width: 120,
      render: (text) => <p> {text?.split("T")[0]}</p>,
    },
   
  ];

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.GetAllStocks();
      const mappedData = res?.data
        ?.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.symbol === item.symbol)
        )
        .map((value) => {
          return {
            ...value,
            key: value.id,
          };
        });
      setallTableData(mappedData);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setallTableData([]);

      setIsloading(false);
    }
  };

  const OnchangeHandler = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    setSerachParams(searchText);

    const theFilterData = allTableData.filter((value, _) =>
      Object.values(value)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredData(theFilterData);
  };
  const RealdataSource = searchParam ? filteredData : allTableData;

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="w-full text-[18px] text-justify leading-loose text-[#424242] ">
      <div className="flex flex-col gap-5 ">
        <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] pb-3">
          Price List
          <span className="border-[1px] border-[#b49132] w-[5%]"></span>
        </p>
        <p>
          You can search for current stocks by typing the codename in the search
          box provided. You could also extend your search by adding dates to the
          search term. For example searching "ZENITH" will return stock price
          for ZENITHBANK for today.
        </p>
        <p>
          *** As released by the Nigerian Stock Exchange (NSE) on{" "}
          <span className="text-[#b49132] underline decoration-dotted text-sm">
            {formattedDate}
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-5">
        <input
          type="search"
          className="border px-2 
       border-[#424242] outline-none w-[40%] flex_center"
          placeholder="search...."
          onChange={(e) => OnchangeHandler(e)}
        />
        <Table
          dataSource={RealdataSource}
          columns={columns}
          loading={isloading}
          // size="small"
          className="dave2 w-full "
         
          scroll={{ x: "max-content" }}
        />
      <div className="text-sm border bg-[#e4e4e4] w-full lg:flex  gap-2 py-3 px-1 font-[500] leading-loose">
                <p className="font-bold text-[#b49132]">LEGEND : </p>
                <p className="">
                  {" "}
                  PCLOSE - Previous Close Price; <span> </span> CHG - Change;
                  RED - Loss; GREEN - Gain;
                  BLUE - No Change
                </p>
              </div>
      </div>
    </div>
  );
};

export default CurrentPriceList;
