import { Collapse, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import BoardCard from "../../components/cards/boardOfdirectors";

const BoardOfDirectors = () => {
  const [boards, setBoards] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getAllBoards();
      const filteredRes = res?.data.filter((value) => {
        return value.teamType === "Board" && value.isActive === true;
      });
      setBoards(filteredRes);
      setIsloading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const items = [
    {
      key: "1",
      label: "Mrs. Ibironke Mojisola Osiyemi  FCA  Chairman",
      children: (
        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            Ibironke Mojisola Osiyemi was born on 9th March 1943 in Ogun State.
            She hails from Ago Iwoye, Ogun State.
          </p>
          <p>
            She had her early education at Imososi Wesley School, Ago Iwoye,
            Ogun State and proceeded to Methodist Modern School, Ago Iwoye and
            Mayflower Secondary School, Ikenne, Ogun State for her Modern II and
            WASC, in 1958 and 1963 respectively. She further proceeded abroad
            for professional courses in Accounting - ACCA sections I - IV; Fulks
            Lynch Tuition School and College of Commerce, Woodgreen, Birmingham,
            England between 1965 - 1969 respectively.
          </p>
          <p>
            She has over 40 years of varied experience in the accountancy
            profession, the auditing sector, business and financial advisory
            services, covering the manufacturing, trading, financial services,
            insurance, banking, construction and regulatory sectors of the
            Nigerian economy.
          </p>
          <p>
            Ibironke, a highly social woman of elegance is the founding member,
            chairperson and Grand patron of Society of Women Accountant of
            Nigeria (SWAN), President, Iganmu Lioness club for 2 years,
            President, Iwoye Elegant Sisters for 5 years, Patron, Yaba College
            of Technology Accountancy Students' Association.
          </p>
          <p>
            She is also a devoted Christian, having served Methodist Church of
            Nigeria in various capabilities as Choir Matron, Rising Stars'
            Matron, Matron, Christian Ladies Friendly Society, Matron, Gospel
            Sowers Society, Vice President of Unity Sisters and Auditor of City
            Mission Methodist Church, Surulere , for over 10 years.
          </p>
          <p>
            She was involved in the training on the job of countless young
            Nigerians as Chartered Accountants. She also lectured at the
            training school on auditing and financial management at KPMG,
            Nigeria. She was the International Liaison Partner for Insurance,
            Banking & Finance for over 10 years at KPMG. She attended several
            overseas training courses in England, Switzerland, South Africa and
            Canada. She participated in several workshops and conferences in UK,
            South Africa, USA, Hong Kong, Singapore, France, Ghana, Togo, Niger
            Republic, Sierra Leone, Gambia and locally in Nigeria.
          </p>
          <p>
            She has also served ICAN in various capacities in ascending order as
            ICAN Council Member, various committee members, 1st Deputy Vice
            President, Vice President, President & Chairman of Council, ICAN.
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: "Mr. Chidi Onyeukwu Ajaegbu FCS, MBF, FCA  MD/CEO",
      children: (
        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            Mr. Chidi Onyeukwu Ajaegbu, FCS, MBF, Dip. in Polygraph (USA), FCA,
            the Golden Jubilee President of the Institute of Chartered
            Accountants of Nigeria, was born coincidentally at about the time
            the Act that established the Institute was enacted in September 1965
            in Kano.
          </p>
          <p>
            He attended Government College, Lagos (1977 - 1982) where he
            obtained his WASC in 1982; and subsequently sat and passed his GCE
            A' Levels in 1984. He trained and qualified as a Chartered
            Accountant in Binder Balogun & Co. (BDO) 1984 - 1988. He holds a
            Masters degree in Banking and Finance from University of Lagos and a
            Diploma in Psycho Physiological Detection of Deception from the
            American International Institute of Polygraph (Stockbridge, Atlanta
            Georgia, USA).
          </p>
          <p>
            He is a Fellow of both the Institute of Chartered Accountants of
            Nigeria (ICAN) and the Chartered Institute of Stockbrokers (CIS).
          </p>
          <p>
            He is the Founder/Pioneer Chief Executive Officer of Mutual Alliance
            (member of the Nigerian Stock Exchange), and he is currently the CEO
            of Heritage Capital Markets Ltd. (another dealing member firm of the
            Nigerian Stock Exchange).
          </p>
          <p>
            He has served the Institute in various capacities as Chairman,
            Deputy Chairman and member of various committees. He has been a
            member of ICAN Governing Council since 2001. He chaired the Ebute
            Metta Building Fund Raising Committee that raised funds for the
            construction of the Secretariat Annex edifice.
          </p>
          <p>
            As the Honorary Treasurer of the Institute in 2010, he
            conceptualised and drove the Institute's Members' Group Life
            Insurance Policy; the E-settlement process and developed strategic
            initiatives with various external partners. And it is on record that
            during his tenure as the Honourary Treasurer, the Institute's cash
            reserve grew by 300%. This singularly, earned him a letter of
            commendation from the Body of Past Presidents of the Institute. He
            was Vice President (2013/2014), 1st Deputy Vice President
            (2012/2013) and 2nd Deputy Vice President (2011/2012).
          </p>
          <p>
            He has also served the accounting profession and the public in
            numerous capacities, as Council member, Association of Accountancy
            Bodies in West Africa (ABWA) 2013; Treasurer, Association of
            Accountancy Bodies in West Africa (ABWA), Member of the Board of Pan
            African Federation of Accountants (PAFA) and Council of
            International Federation of Accountants (IFAC), New York, USA.
          </p>
          <p>
            He is currently a council member of the Chartered Institute of
            Stockbrokers CIS. An international speaker, he has facilitated
            papers at CIPFA (London) conference, Afro- American Investment
            conference organised by Yale Business school in New Haven
            Connecticut USA, United Nation ISAR workshops in Geneva amongst
            others.
          </p>
          <p>
            Mr. Chidi O. Ajaegbu was a Council member of the National Governing
            Council of the Nigerian Stock Exchange from 2005 to 2008 and served
            in the F & GPC, Quotations Committees, among other Committees of the
            Nigerian Stock Exchange.
          </p>
          <p>
            He is an Audit Committee member of Guinness Plc. and has served on
            the board of a number of quoted companies.
          </p>
          <p>
            He is also a Diocesan Board Adviser to Aba North Dioceses of the
            Anglican Communion.
          </p>
          <p>
            Mr. Ajaegbu is a philanthropist of repute who currently runs the
            Chidi Ajaegbu Educational Foundation which awards scholarship every
            year to over 100 students of tertiary institutions in Abia and Lagos
            States respectively.
          </p>
          <p>
            He has received numerous awards which include: Unilag Outstanding
            Alumni Achievers Award, "Enyi Abia" from the Executive Governor of
            Abia State, Rotary Club Paul Harris Fellow Award, amongst others.
          </p>
          <p>
            He is an active sportsman and a football enthusiast. He is a strong
            fan of the popular Arsenal Football Club.
          </p>
          <p>He is happily married with children.</p>
        </div>
      ),
    },
    {
      key: "3",
      label:
        "Dr Elijah Oguobi Ogbuokiri PhD, FCA, CFA, FPAM, MBA, ACTI   Director",
      children: (
        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            Dr Elijah Oguobi Ogbuokiri born over 58 years ago in Amapu Ntigha ,
            Isiala Ngwa LGA of Abia State is a seasoned Chartered Accountant in
            practice, a Professional Manager, Entrepreneur and Pastor by calling
            of the Lord Jesus Christ. He is the MD/ CEO of Ebbinos Consulting
            Limited, a Financial Advisory and Management Consultancy firm, and
            the Principal Partner of Oguobi Ogbuokiri & Co. (Chartered
            Accountants).
          </p>
          <p>
            He obtained General Certificate of Education GCE O/L & Advanced
            Level both in 1979 and 1981 respectively. He gained Diploma in
            Accounting and Finance from The School of Accountancy and Buisness
            Studies Lagos in 1983. In 1998, he obtained PGD in Finance and
            Banking from Delta State University Abraka and then proceeded for
            his Masters in Business Administration (MBA) from the same
            University in 2000.
          </p>
          <p>
            In 2013, he was awarded Doctor of Philosophy in Development
            Management Studies (PhD) from American European University,
            Dominican after 3 years of rigorous academic exercise.
          </p>
          <p>
            He trained and qualified as Chartered Accountant in 1988 and was
            awarded fellowship of ICAN in 1997. He is a Certified Forensic
            Accountant , Fellow of Public Administration and Management
            Development Institute (PAMDI) and a Chartered Tax Practitioner.
          </p>
          <p>
            He had his Professional Accounting Training with Chukuwma Offor &
            Co. (Chartered Accountants) Lagos where he completed his mandatory
            training and obtained his license to practice as a Chartered
            Accountant.
          </p>
          <p>
            Prior to forming Ebbinos Consult and the Accounting firm in 1994, he
            worked for over 15 years in some organizations including Nigerian
            National Petroleum Corporation (NNPC) where he spent over 12 years
            in the Finance and Accounts Division. He worked in various Division
            of the Corporation and left as Head of Capital Budget in 1991. After
            leaving NNPC, he went to the Financial Services Industry and worked
            with two Mortgages Banks (Morgan Savings and Loan and Trendex
            Savings and Loan) as Financial Controller and Assistant General
            Manager (Operations) respectively.
          </p>
          <p>
            Dr Elijah in his capacity as a Management Consultant and Chartered
            Accountant has consulted on many projects and programmes, his
            auditing and investigation experiences span through many areas and
            organizations both in the Private and Public sectors.
          </p>
          <p>
            He has also contributed to the growth and development of the
            Institute of Chartered Accountants of Nigeria having served the
            Institute in various capacities including serving in some committees
            of the Institute as well as serving at the District level. He served
            in the Executive Committee of Lagos Mainland District Society for
            over 12 years and become the District Chairman in 2012.
          </p>
          <p>
            In 2009, he envisioned and established with the help of other
            stakeholders The Public Administration and Management Development
            Institute (PAMDI) and became the first Director General. A position
            he holds till date. The Institute has branches across the six
            geopolitical zones of the country and members increasing in hundreds
            within the public and private sectors.
          </p>
          <p>
            He attended several courses, workshop and seminars both locally and
            internationally. He presents papers in seminars and workshop on
            professional and management issues.
          </p>
          <p>
            Dr Elijah Ogbuokir is Pastor in Bride Of Christ Exhortation
            Assembly, Lagos where he enjoys preaching the Gospel of Jesus Christ
            without charge and working as the Church Administrator
          </p>
        </div>
      ),
    },
    {
      key: "4",
      label:
        "Prof. Emmanuel N. Emenyonu BSC, MBA, MACC, Ph.D, FCA. LLB Director",
      children: (
        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            Emmanuel Emenyonu is a Professor of Accounting and the immediate
            past Chairperson of the Department of Accounting, Southern
            Connecticut State University, USA. He has extensive teaching
            experience in the fields of Finance, Financial Accounting, Taxation,
            Accounting Theory, Accounting Information Systems, Fraud and
            Forensic Accounting and International Accounting with leading
            universities such as Yale University, New Haven, Connecticut, Howard
            University, Washington, DC, Sacred Heart University, Fairfield,
            Connecticut and Quinnipiac, Hamden University in the USA; University
            of Nigeria Enugu Campus and Covenant University, Ota in Nigeria.
          </p>
          <p>
            He has B.Sc. Accounting and MBA in Finance from University of
            Nigeria; M.ACC. Accounting and Ph.D, in International Accounting
            from the University of Glasgow, Scotland and Bachelor of Laws, LLB
            from the University of London, England. Professor Emmanuel Emenyonu
            is a Fellow of the Institute of Chartered Accountants of Nigeria and
            at some point has been professionally affiliated with numerous
            organizations such as American Accounting Association, International
            Association for Accounting Education and Research, Academy of
            Economics and Finance, Financial Executives International,
            Association of Certified Fraud Examiners.
          </p>
          <p>
            He has published numerous articles in peer reviewed and practitioner
            journals such as the Financial Times of London (UK), Accounting and
            Business Research (UK), Australian Accounting Review (Australia),
            International Journal of Accounting (USA), Journal of African
            Business (USA), Nigerian Accountant (Nigeria), Journal of Accounting
            and Finance Research (USA). International Journal of Accounting,
            Auditing and Performance Evaluation, First Bank Quarterly Review
            (Nigeria), Advances in International Accounting (USA).
          </p>
          <p>
            Professor Emenyonu is a winner of numerous academic honors and
            awards such as: British Government Foreign and Commonwealth Office
            Postgraduate Fellowship; David G. Lindsay Prize in Accountancy (Best
            graduating Master of Accountancy Student, Glasgow University);
            Glasgow University Postgraduate Scholarship; Howard University
            Summer Research Grant; Outstanding Paper Award, Academy of Economics
            and Finance, Savannah, Georgia; Winner, School of Business Faculty
            Research Award, Southern Connecticut State University, USA; among
            others.
          </p>
          <p>
            He has spoken and presented papers at different national and
            international conferences including: European Accounting
            Association, Madrid Spain; Academy of International Business,
            Boston, USA; World Congress of the International Association for
            Accounting Education and Research, Paris, France; among others.
          </p>
          <p>
            He is a book reviewer for major global publishers such as : John
            Wiley and Sons and McGraw Hill Publishers. He is currently the
            International Editor, Journal of Industrial, Business & Economic
            Research (JIBER). He is also the President of United States And
            Africa Development Organization (USAADO), a Connecticut, Unites
            States tax exempt organization established to further the goals of
            Connecticut Senate Bill 880/SA 09-14, namely, the promotion of
            productive business exchanges between Connecticut and United States
            businesses with businesses in Sub-Saharan African countries. He also
            serves on the governing and advisory boards of a number of
            organizations both within and outside Nigeria, for example Felix
            Ohiwerei Foundation and GO International.
          </p>
        </div>
      ),
    },
    {
      key: "5",
      label:
        "Oluseyi Olanrewaju  HND, BSc, MBA, MSc, AMNIM, M.IoD, FCCM, FCTI, FCCA, FCA  Director",
      children: (
        <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
          <p>
            Seyi is an accounting graduate from Yaba College of Technology and
            Oxford Brookes University UK, where he received HND - Accountancy
            and Finance and BSc - Applied Accounting respectively with flying
            colours. He is also an Alumni of Lagos State University, Ojo, Lagos,
            Nigeria, where he bagged Masters in Business Administration (MBA)
            specializing in Financial Management and University of Leicester,
            UK, where he bagged MSc in Finance.
          </p>
          <p>
            He is an Associate/Fellow member of the Institute of Chartered
            Accountant of Nigeria (ICAN), Chartered Institute of Taxation of
            Nigeria (CITN), Nigerian Institute of Management (Chartered) (NIM),
            Institute of Certified Cost Management of Nigeria (ICCMN), Institute
            of Directors, Nigeria (IOD), The Association of Chartered Certified
            Accountants (ACCA), UK.
          </p>
          <p>
            Seyi is a seasoned IFRS/IPSAS expert and respected analyst on
            Corporate Governance and Financial Reporting matters, with over
            fifteen (15) years practical training experience spanning virtually
            across every section of business endeavour and the academics. He has
            attended several trainings on Auditing, Leadership, Corporate
            Governance, Marketing, Financial Accounting & Reporting, IFRS, IPSAS
            and Management in Nigeria (including Lagos Business School - LBS)
            and abroad. He has been privileged to train and work in such places
            as Segun Adelanwa & Co (Chartered Accountants), ExxonMobil Nigeria
            Unlimited, PricewaterhouseCoopers (Chartered Accountants), Zenith
            Bank Plc and MTN Nigeria Communications Limited, where he was the
            pioneer head Treasury Operations, the pioneer Technical Accounting
            Manager (IFRS/IPSAS Specialist) and the Assistant Treasurer, MTN
            Nigeria Multipurpose Cooperative Society. He was the Senior
            Financial Operations Manager/Leader, General Electric Company (GE),
            USA.
          </p>
          <p>
            He was the team lead of IFRS conversion (implementation) committee
            in MTN Nigeria, GE Nigeria and IS Internet Solutions and also, has
            been involved in IFRS conversion assignments for major companies in
            Nigeria. He was the President of Mustard Seed Investment Club
            (MUSIC) and President of Professional Accountancy Students' Society,
            Yaba College of Technology (PASS), the current General Secretary,
            Association of Accountancy Tuition Centers of Nigeria (AATC) and the
            current Auditor of Magodo Phase1 Gateway Zone CDA. He was the
            Director of Finance (Board Member)/Chief Financial Officer (CFO) of
            IS InternetSolutions Limited - Nigeria, a subsidiary of Dimension
            Data (Pty), South Africa (member of NTT Group Japan). Now the Vice
            President, Finance and Administration, KONGA Online Shopping Limited
            - The largest online shopping mall in Africa.
          </p>
          <p>
            Seyi has tremendous passion for imparting knowledge to the would-be
            accounting (students) and finance professionals preparing for ICAN,
            ACCA, CIS and CFA examinations and existing professionals. He has
            been lecturing for the above Institutes' examinations on continuous
            basis for over fifteen (15) years i.e 30 diets and has lectured in
            virtually all training centers in Lagos-Nigeria, namely: Students'
            PYE Nigeria Limited, Mayo Associates, Royal Tutors, WYSE Associates,
            Safe Associates, Synergy Professionals, SPEECS Tutors, Custom Street
            Associates (CSA), CBA Academy, ABS Tutors, PASS Center, Executive
            Business School (EBS), Yaba College of Technology, Risk Free
            Standard Associates Limited, Leverage Associates Limited and Babcock
            University, Nigeria.
          </p>
          <p>
            He is also a major resource person to ICAN, ACCA and CIS examination
            revision programmes and at KANO/CALABAR special ICAN centres. His
            specialty is in Auditing, Financial Accounting, Reporting and
            Analysis. He is popularly referred to by his students as
            "MASSATTACK". He is a regular guest speaker at the Institute of
            Chartered Accountants of Nigeria (ICAN) Mandatory Continuous
            Professional Education (MCPE) and the Association of Chartered
            Certified Accountants' (ACCA) Continuing Professional Developments
            (CPD), Seminars, Workshops and a faculty to major training outfits
            in Nigeria. He is also a member ICAN Annual Accountants Conference
            Transition Committee, member ICAN Annual Accountants Conference
            Committee, Member of ICAN Mutual Cooperation Agreement with Tertiary
            Institutions Implementation and Monitoring (MCATII&MC), Member -
            ICAN Strategy, Advocacy and Performance Tracking Committee, member
            of the ICAN Members' Education and Training Committee and an
            external monitor on IFRS Trainings for the Institute - ICAN. Member
            - ICAN Students Affairs Committee.
          </p>
          <p>
            He is the author and co-author of IFRS Pal - Handy Approach (the
            first indigenous text book on IFRS) and Executorship, Trusts, Law
            and Accounts (ETLA) respectively. Seyi was awarded for his
            outstanding contributions over the years to accountancy development
            in Nigeria by ACCA, outstanding contribution to the 44th Annual
            Accountants Conference and as the most outstanding PASS Ambassador
            by the Professional Accountancy Students' Society (PASS), Yaba
            College of Technology.
          </p>
          <p>
            Seyi enjoy meeting people, dancing, reading and teaching. He is
            happily married to Kofoworade Olanrewaju and blessed with wonderful
            children.
          </p>
          <p>
            He is a co-founder and Executive Chairman of Risk Free Standard
            Associates Limited and Leverage Associates Limited, foremost
            accredited ICAN & ACCA tuition providers and Dimkem Consulting
            Limited. He also sits on the board on many companies as a
            Non-Executive Director.
          </p>
        </div>
      ),
    },
  ];
  return (
    <div className="w-full">
      <p className="flex justify-center items-start flex-col  lg:text-[30px] text-[24px] leading-loose  font-bold text-[#373c5f] capitalize">
        Board of Directors
        <span className="border-[1px] border-[#b49132] w-[5%]"></span>
      </p>

      <div className="flex  gap-8 flex-col text-[18px] text-justify leading-loose text-[#424242]">
        <p>
          Our Board of Directors is made up of people who have distinguished
          themselves in various professions and fields. They include:
        </p>
      </div>
      <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching board of directors...."}
        className=" text-[#9b7c27] font-bold"
      >
        <div>
          {error ? (
            <Result
              status="500"
              title="Refresh"
              subTitle="Sorry, something went wrong."
              // extra={<Button type="primary">Re</Button>}
            />
          ) : boards.length === 0 ? (
            <div className="h-[200px] justify-center items-center flex ">
              <p className="text-center capitalize text-lg font-bold ">
                No board Of directors record
              </p>
            </div>
          ) : (
            boards.map((boardDetails, index) => (
              <BoardCard boardDetails={boardDetails} key={index} />
            ))
          )}
        </div>
      </Spin>
    </div>
  );
};

export default BoardOfDirectors;
