import { Link } from "react-router-dom";
import footer_logo from "../../Assets/footer-logo (1).png";
import { BsEnvelope } from "react-icons/bs";
import {
  FacebookOutlined,
  LinkedinFilled,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
const Footer = () => {
  return (
    <div className="bg-[#1F2134] ">
      <footer>
        <div className="md:w-[85%] w-[80%] mx-auto">
          <div className="grid grid-cols-1 justify-center items-center md:grid-cols-3 md:justify-start md:items-start pt-10 gap-10">
            <div className="flex justify-center items-start flex-col gap-5 leading-snug">
              <div>
                <img src={footer_logo} alt="" />
              </div>
              <p className="text-start text-white ">
                At Heritage Capital Markets Limited, you enjoy a personal
                relationship with our investment professionals. We see investing
                only from your perspective, and make recommendations based on
                actually listening to you and understanding your needs. Welcome
                to your investment world.
              </p>
            </div>

            <div className="flex flex-col justify-center items-start gap-8 md:gap-[65px]">
              <h2 className="font-[600] leading-20 text-[18px] text-[whitesmoke] flex flex-col">
                Explore
                <span className="w-[60%] border border-[#b49132]"></span>
              </h2>
              <div className="flex  justify- items-  w-full gap- text-[darkgray]">
                <ul className=" w-full border-r-[1px] border-[darkgray]">
                  <li>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li>
                    <Link to={'/AboutUs/'}>About Us </Link>
                  </li>
                  <li>
                    <Link to={'/Products/'}>Products & services</Link>
                  </li>
                
                  <li>
                    <Link to={'/#Business'}>Business News</Link>
                  </li>
                  <li>
                    <Link to={'/Contact'}>Contact Us</Link>
                  </li>
                </ul>

                <ul className=" w-full pl-10">
                  <li>
                    <Link to={'/Research/'}> Research</Link>
                  </li>
                  <li>
                    <Link to={'/Products/Brokerage_Dealing'}>Stocks</Link>
                  </li>
                  <li>
                    <Link to={'/Research/Company_Research'}>Market Reports</Link>
                  </li>
                  {/* <li>
                    <Link >FAQs</Link>
                  </li> */}
                  <li>
                    <Link to={'/Individual_offline'}>Downloads</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-col justify-center items-start gap-8 md:gap-[65px]">
              <h2 className="font-[600] leading-20 text-[18px] text-[whitesmoke] flex flex-col">
                Get Connected
                <span className="w-[50%] border border-[#b49132]"></span>
              </h2>
              <div className=" flex justify-center items-start flex-col gap-8">
                <p className="text-justify text-[darkgray]">
                  Get connected! Subscribe to our newsletter service and be the
                  first to get the latest stock market reports, business news
                  and trends.
                </p>

                <form className="w-full ">
                  <div className="flex justify-center items-start gap-3 w-full flex-col ">
                    <div className="flex justify-center items-center w-full ">
                      <input
                        type="search"
                        className="w-full p-3"
                        placeholder="your email address"
                      />
                      <button
                        type="submit"
                        className="bg-[#b49132] p-4 w-[20%] justify-center flex items-center"
                      >
                        <BsEnvelope className="text-white font-bold w-full " />
                      </button>
                    </div>

                    <ul className="flex gap-2">
                      <li className=" px-3 py-2 rounded-[100%] bg-[#b49132]">
                        <LinkedinOutlined className="text-white" />
                      </li>
                      <li className=" px-3 py-2 rounded-[100%] bg-[#b49132]">
                        <TwitterOutlined className="text-white" />
                      </li>
                      <li className=" px-3 py-2 rounded-[100%] bg-[#b49132]">
                        <FacebookOutlined className="text-white" />
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-[darkgray] md:pt-10 pt-5 md:pb-5 pb-12 mt-10 md:w-[85%] w-[90%] mx-auto">
            <div className="flex justify-between items-center md:flex-row flex-col" >
              <div className="text-white md:text-[14px] text-[12px] text-center pb-5">
                Copyright &copy;2023{" "}
                <a href="#">
                  Heritage Capital Markets Limited, All Rights Reserved
                </a>
              </div>
            
                {/* <ul className="flex justify-center items-center gap-3 text-sm md:gap-8 text-[darkgray]">
                  <li>
                    <Link >Home</Link>
                  </li>
                  <li>
                    <Link >FAQs</Link>
                  </li>
                  <li>
                    <Link >Glossary</Link>
                  </li>
                  <li>
                    <Link >Site Map</Link>
                  </li>
                  <li>
                    <Link >Privacy Policy</Link>
                  </li>
                </ul> */}
              </div>
           
          </div>
      </footer>
    </div>
  );
};

export default Footer;
